<template>
    <v-btn
        class="btn-user-menu"
        color="secondary"
        aria-label="User Menu"
        @click="emit('on-click', $event)"
        icon
    >
        <UserIcon></UserIcon>
    </v-btn>
</template>

<script lang="ts" setup>
    import UserIcon from '~/components/header/menus/UserIcon/UserIcon.vue';
    
    const emit = defineEmits<{
        'on-click': [value: Event];
    }>();

</script>

<style lang="scss"></style>
